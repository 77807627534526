/* eslint-disable */
import React from 'react';
import styled, {css} from 'styled-components';
import PropTypes from 'prop-types';
import * as colors from '~sass/pb_styleguide/base/_exports.sass';

//
//   // If there is already an h1 in the page, let's not duplicate it.
//   // variants should ONLY be classes used in pb_styleguide/base/typography.sass
//   // you may add one more class for page-specific margin/positioning.
//   // TODO make rules in the component that enforces this stuff & helps prevent typos
//
//   // <Typography variant="header1" as="h2">
//   //   This text uses an h2 element but looks like an h1!
//   // </Typography>
//
//   return (
//     <DynamicComponent tag={props.as} className={`${props.className} ${props.variant} ${props.size}`} {...props}>
//       {props.children}
//     </DynamicComponent>
//   )
// }

export const Colors = css`
  color: ${({fontColor}) => fontColor || colors.mexican_opal};
`;

const StyledDynamicComponent = styled.p`
  ${Colors}
`;

// const DynamicComponent = ({tag="p", color=$eviteBlue, children, ...props }) => {
//   const WithComponent = StyledDynamicComponent.withComponent(tag, color);
//   return <WithComponent color={color} {...props}>{children}</WithComponent>;
// };

export function Typography({
  className,
  classNames,
  color,
  size,
  inline,
  semibold,
  variant,
  ...props
}) {
  // If there is already an h1 in the page, let's not duplicate it.

  // <Typography variant="h1" as="h2">
  //   This text uses an <h2> element but looks like an <h1>
  // </Typography>

  // todo - There seems to be a race condition preventing color
  // attributes from being respected in some nested components
  // (unless the same color has already been used
  // in a Typog element in a parent component)

  const colorValue = color && colors[color];

  return (
    <StyledDynamicComponent
      tag={props.as}
      className={`${classNames || ''} ${className || ''} ${variant || ''} ${
        semibold ? 'semibold-text' : ''
      } ${size || ''} ${inline ? 'inline' : ''}`}
      style={{color: colorValue}}
      {...props}
    >
      {props.children}
    </StyledDynamicComponent>
  );
}

const typo_mixins_arr = [
  'header1',
  'header2',
  'header3',
  'header4',
  'header5',
  'paragraph1',
  'paragraph2',
  'paragraph3',
  'paragraph4',
  'list1',
  'list2',
  'label1',
  'label2',
  'label3',
  'link',
  'premium',
];

Typography.propTypes = {
  id: PropTypes.string,
  classNames: PropTypes.string,
  size: PropTypes.oneOf(['smaller', 'small', 'medium', 'large']),
  color: PropTypes.oneOf(Object.keys(colors)), // some of the keys here are not colors but good enough...
  variant: PropTypes.oneOf(typo_mixins_arr).isRequired,
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.string]).isRequired,
  'data-qa-id': PropTypes.string,
  as: PropTypes.string,
  inline: PropTypes.bool,
  htmlFor: PropTypes.string,
  semibold: PropTypes.bool,
};
