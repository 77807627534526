import React from 'react';
import {charcoal} from '~sass/pb_styleguide/base/_exports.sass';

export function PB_Checkmark(props) {
  const {color = charcoal, className = '', ratio = 1} = props;

  // default dimensions
  const width = 32;
  const height = 32;

  // Use ratio to calculate width and height
  const style = {height: `${ratio * height}px`, width: `${ratio * width}px`};

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M13.3334 20.2293L25.5894 7.97202L27.476 9.85735L13.3334 24L4.84802 15.5147L6.73336 13.6293L13.3334 20.2293Z"
        fill={color}
      />
    </svg>
  );
}
