import React, {createElement, Fragment, useEffect, useRef} from 'react';
import {render} from 'react-dom';
import PropTypes from 'prop-types';
import {autocomplete} from '@algolia/autocomplete-js';

const addQaIdLocator = (containerRef, selector, qaId) => {
  const el = containerRef.current.querySelector(selector);
  if (el) {
    el.setAttribute('data-qa-id', qaId);
  }
};

export const AutoCompleteSearchBar = (props) => {
  const {classNames} = props;
  const containerRef = useRef(null);
  useEffect(() => {
    if (!containerRef.current) {
      return undefined;
    }
    const search = autocomplete({
      container: containerRef.current,
      detachedMediaQuery: 'none',
      renderer: {createElement, Fragment, render},
      renderNoResults({render: renderElem}, root) {
        renderElem(null, root);
      },
      render({elements, render: renderElem, html}, root) {
        const {suggestion, template} = elements;
        renderElem(
          html`<div className=${classNames.panelLayout}>
            <div>
              <div>${suggestion}</div>
              <div>${template}</div>
            </div>
          </div>`,
          root
        );
      },
      ...props,
    });

    addQaIdLocator(containerRef, '.search-bar__input', 'input-search-bar');
    addQaIdLocator(
      containerRef,
      '.aa-SubmitButton.search-bar__input--icons',
      'submitSearchQueryIconButton'
    );

    return () => {
      search.destroy();
    };
  }, [props]);

  return <div id="autocomplete-input" data-qa-id="meganavSearchInput" ref={containerRef} />;
};

AutoCompleteSearchBar.propTypes = {
  classNames: PropTypes.objectOf(PropTypes.string),
};

AutoCompleteSearchBar.defaultProps = {
  classNames: undefined,
};
