import {action, computed, observable} from 'mobx';

import {toJSON, Emitter} from 'evite';

const indexes = {};

export class Collection extends Emitter {
  // get model() {return Model };
  @observable models = [];

  constructor(models = [], options = {model: null}) {
    super();

    models = Array.from(models || []);
    options = {...options};

    if (!this.model && options.model) {
      this.model = options.model;
    }

    this.reset(models, options);
  }

  parse(list) {
    const Model = this.model;
    list = list || [];
    const {length} = list;

    const modelList = [];

    for (let i = 0; i < length; ++i) {
      const data = list[i];
      const isModel = data instanceof Model;
      const model = isModel ? data : new Model(toJSON(data));
      modelList.push(model);
    }

    return modelList;
  }

  reset(list, options = {silent: false}) {
    if (!options.silent) {
      const event = new CustomEvent('before:reset', {
        detail: list ? list.concat() : [],
        cancelable: true,
      });
      this.dispatchEvent(event);
      if (event.defaultPrevented) {
        return;
      }
    }

    if (list && list.length) {
      const newModels = this.parse(list);
      this.replace(newModels);
    } else {
      this.replace([]);
    }

    if (!options.silent) {
      this.dispatchEvent(new CustomEvent('reset'));
    }
  }

  clear() {
    this.reset([]);
  }

  pluck(propertyName) {
    return this.map((model) => model[propertyName]);
  }

  @action
  append(list) {
    list = Array.isArray(list) ? list : Array.from(arguments);
    this.models.push.apply(this.models, list);
  }

  findWhere(props) {
    const index = this.findIndexWhere(props);
    if (index === -1) {
      return null;
    }

    return this.models[index];
  }

  findIndexWhere(propsOrFunction) {
    const modelList = this.models.concat();
    for (let i = 0; i < modelList.length; ++i) {
      const model = modelList[i];
      let isMatch = false;

      if (propsOrFunction && typeof propsOrFunction === 'object') {
        const props = propsOrFunction;
        isMatch = true;
        for (const key in props) {
          if (props[key] !== model[key]) {
            isMatch = false;
            break;
          }
        }
      } else if (evite.isFunction(propsOrFunction)) {
        const fn = propsOrFunction;
        isMatch = fn(model, i, modelList);
      } else {
        evite.assertMessage(
          `Expected argument to be of type function or non null object, got: ${typeof propsOrFunction}`
        );
        return -1;
      }

      if (isMatch) {
        return i;
      }
    }

    return -1;
  }

  toJSON() {
    return toJSON(this.models);
  }

  @computed
  get length() {
    return this.models.length;
  }
}

[
  'concat',
  'every',
  'filter',
  'find',
  'findIndex',
  'forEach',
  'get',
  'indexOf',
  'intercept',
  'join',
  'lastIndexOf',
  'map',
  'move',
  'peek',
  'observe',
  'peek',
  'pop',
  'push',
  'reduce',
  'reduceRight',
  'remove',
  'replace',
  'reverse',
  'shift',
  'slice',
  'some',
  'sort',
  'splice',
  'spliceWithArray',
  'unshift',
].forEach((key) => {
  if (Collection.prototype[key]) {
    return;
  }

  Collection.prototype[key] = action(key, function () {
    const args = Array.from(arguments);
    return this.models[key].apply(this.models, args);
  });
});

for (let i = 0; i < 1000; ++i) {
  indexes[i] = undefined;
  (function (index) {
    Object.defineProperty(Collection.prototype, index, {
      get() {
        return this.models[index];
      },
      set: action(function (value) {
        this.models[index] = value;
      }),
    });
  })(i);
}
