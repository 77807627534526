import {useCallback, useEffect, useState} from 'react';

export const useMatchQuery = (query, listeners = []) => {
  const windowSizeQuery = window.matchMedia(query);
  const [matches, setMatches] = useState(windowSizeQuery.matches);

  const handleWindowResize = useCallback((sizeQuery) => {
    setMatches(sizeQuery?.matches);
  }, []);

  listeners.push(handleWindowResize);

  // support older browser versions
  windowSizeQuery.addEventListener ||= (_, action) => windowSizeQuery.addListener(action);
  windowSizeQuery.removeEventListener ||= (_, action) => windowSizeQuery.removeListener(action);

  useEffect(() => {
    listeners.forEach((listener) => {
      listener(windowSizeQuery);
      windowSizeQuery.addEventListener('change', listener);
    });
    return () =>
      listeners.forEach((listener) => windowSizeQuery.removeEventListener('change', listener));
  }, []);

  return {windowSizeQuery, matches};
};
