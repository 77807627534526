import cx from 'classnames';
import React, {useState, useEffect, useRef} from 'react';
import PropTypes from 'prop-types';

export const Button = React.forwardRef((props, ref) => {
  const [processing, setProcessing] = useState(false);
  const mountedRef = useRef(true);

  const onClick = async (e) => {
    // If the props.onClick handler returns a promise we wait until it completes before allowing another click to go through.
    if (processing) return false;
    const value = props.onClick(e);
    if (value && typeof value.then === 'function') {
      if (!props.disableProcessing) {
        setProcessing(true);
      }
      if (props.override) {
        e.preventDefault();
        e.stopPropagation();
      }
      value.finally(() => {
        if (mountedRef.current) {
          setProcessing(false);
        }
      });
    }
    return value;
  };

  useEffect(
    () =>
      // track when we are unmounted so we don't update the state after un-mounting
      () => {
        mountedRef.current = false;
      },
    []
  );

  return (
    <button
      data-clipboard-text={props.dataClipboardText}
      id={props.id}
      data-qa-id={props['data-qa-id']}
      data-impression-tracking={props['data-impression-tracking']}
      data-qa-value={props['data-qa-value']}
      data-qa-tigran={props['data-qa-tigran']}
      className={cx('button', {
        [props.className]: !!props.className,
        rounded: !!props.rounded,
        [props.variant]: !!props.variant,
        large: props.size === 'large',
        small: props.size !== 'large',
        wide: props.fullWidth,
        tight: props.tight,
        nowrap: props.noTextWrap,
        processing,
        'icon-only': props.icon && !props.children,
      })}
      onClick={props.onClick ? onClick : undefined}
      onMouseEnter={props.onMouseEnter}
      onMouseLeave={props.onMouseLeave}
      disabled={props.disabled}
      // eslint-disable-next-line react/button-has-type
      type={props.type ?? (props.formSubmit ? 'submit' : 'button')}
      title={props.title}
      ref={ref}
    >
      {processing && <span className="evt-spinner inline" />}
      {props.icon}
      {processing && props.processingChildren ? props.processingChildren : props.children}
    </button>
  );
});

Button.propTypes = {
  id: PropTypes.string,
  className: PropTypes.string,
  variant: PropTypes.oneOf([
    'primary',
    'secondary',
    'transparent',
    'unstyled-button',
    'link',
    'featured', // currently used for the CF's goh & cohost buttons
  ]),
  size: PropTypes.oneOf(['small', 'large']),
  tight: PropTypes.bool,
  onClick: PropTypes.func,
  onMouseEnter: PropTypes.func,
  onMouseLeave: PropTypes.func,
  processingChildren: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.string]),
  formSubmit: PropTypes.bool,
  fullWidth: PropTypes.bool,
  disabled: PropTypes.bool,
  'data-qa-id': PropTypes.string.isRequired,
  'data-impression-tracking': PropTypes.string,
  'data-qa-value': PropTypes.string,
  type: PropTypes.string,
  icon: PropTypes.element,
  override: PropTypes.bool,
  noTextWrap: PropTypes.bool,
  disableProcessing: PropTypes.bool,
  title: PropTypes.string,
  rounded: PropTypes.bool,
};

Button.defaultProps = {
  id: '',
  className: '',
  variant: 'primary',
  size: 'small',
  tight: false,
  onClick: null,
  onMouseEnter: null,
  onMouseLeave: null,
  processingChildren: null,
  children: null,
  formSubmit: false,
  fullWidth: false,
  disabled: false,
  'data-impression-tracking': null,
  'data-qa-value': null,
  type: 'button',
  icon: null,
  override: false,
  noTextWrap: false,
  disableProcessing: false,
  title: '',
};
