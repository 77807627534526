import React from 'react';
import {charcoal} from '~sass/pb_styleguide/base/_exports.sass';

export function PB_Chevron(props) {
  const {color = charcoal, className = '', ratio = 1, direction = 'right'} = props;

  // default dimensions
  const width = 32;
  const height = 32;

  // Use ratio to calculate width and height
  const rotateFromRight = (dir) => {
    switch (dir) {
      case 'right':
        return 0;
      case 'left':
        return 180;
      case 'down':
        return 90;
      case 'up':
        return 270;
      default:
        return 0;
    }
  };

  // Use ratio to calculate width and height
  const style = {
    transition: 'transform 0.3s',
    transform: `rotate( ${rotateFromRight(direction)}deg)`,
    height: `${ratio * height}px`,
    width: `${ratio * width}px`,
  };

  return (
    <svg
      viewBox={`0 0 ${width} ${height}`}
      fill="none"
      style={style}
      className={className}
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M17.5626 16L10.9626 9.39998L12.848 7.51465L21.3333 16L12.848 24.4853L10.9626 22.6L17.5626 16Z"
        fill={color}
      />
    </svg>
  );
}
