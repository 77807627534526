// extracted by mini-css-extract-plugin
var _1 = "#709FFF";
var _2 = "#E1E3E6";
var _3 = "#FFECE9";
var _4 = "https://g0.evitecdn.com/";
var _5 = "#101B21";
var _6 = "#FF9548";
var _7 = "#C75300";
var _8 = "#B9BCBF";
var _9 = "#92959B";
var _a = "#E2ECFF";
var _b = "#66DB86";
var _c = "#20883C";
var _d = "#28A842";
var _e = "#FF6348";
var _f = "#FFECE9";
var _10 = "#FFECE9";
var _11 = "#D62000";
var _12 = "#709FFF";
var _13 = "#E2ECFF";
var _14 = "#E2ECFF";
var _15 = "#2424E5";
var _16 = "#FF9548";
var _17 = "#E092FF";
var _18 = "#B9BCBF";
var _19 = "#66DB86";
var _1a = "#E3FBE4";
var _1b = "#E3FBE4";
var _1c = "#20883C";
var _1d = "#FF9548";
var _1e = "#FFF5ED";
var _1f = "#FFF5ED";
var _20 = "#C75300";
var _21 = "#66DB86";
var _22 = "#F5F5F7";
var _23 = "#B9BCBF";
var _24 = "#B9BCBF";
var _25 = "#FFFFFF";
var _26 = "#FFFFFF";
var _27 = "calc(80rem - 0.1rem)";
var _28 = "min-width: 48rem";
var _29 = "calc(61.876rem - 0.1rem)";
var _2a = "min-width: 61.875rem";
var _2b = "#EBEAFD";
var _2c = "#F5F3F2";
var _2d = "#5352EB";
var _2e = "#5352EB";
var _2f = "#5352EB";
var _30 = "calc(48rem - 0.1rem)";
var _31 = "min-width: 31.25rem";
var _32 = "rgba(106, 110, 113, 0.2)";
var _33 = "#FF6348";
var _34 = "#D62000";
var _35 = "#E3FBE4";
var _36 = "rgba(227, 251, 228, 0.5)";
var _37 = "#6A6E71";
var _38 = "#92959B";
var _39 = "#6A6E71";
var _3a = "#FFFFFF";
var _3b = "#E092FF";
var _3c = "#C10097";
var _3d = "#FF9548";
var _3e = "#E092FF";
var _3f = "#66DB86";
var _40 = "#2E3136";
var _41 = "#E1E3E6";
var _42 = "calc(31.25rem - 0.1rem)";
var _43 = "min-width: 23.375rem";
var _44 = "clamp(2.5rem, 11vw, 5rem)";
var _45 = "clamp(1.5rem, 5vw, 3rem)";
var _46 = "clamp(1rem, 3vw, 1.5rem)";
var _47 = "#5352EB";
var _48 = "#2424E5";
var _49 = "#CA6E2E";
var _4a = "#E2ECFF";
var _4b = "#101B21";
var _4c = "#FFF5ED";
var _4d = "#F4E2D5";
var _4e = "#FFFFFF";
export { _1 as "aquamarine", _2 as "borderColor", _3 as "bubbleGum", _4 as "cdnPath", _5 as "charcoal", _6 as "citrine", _7 as "citrineWebText", _8 as "cloud", _9 as "coin", _a as "cottonCandy", _b as "emerald", _c as "emeraldWebText", _d as "eviteGreen", _e as "feedbackError", _f as "feedbackErrorBg", _10 as "feedbackErrorSubtle", _11 as "feedbackErrorText", _12 as "feedbackInformation", _13 as "feedbackInformationBg", _14 as "feedbackInformationSuble", _15 as "feedbackInformationText", _16 as "feedbackMaybe", _17 as "feedbackNo", _18 as "feedbackNoReply", _19 as "feedbackSuccess", _1a as "feedbackSuccessBg", _1b as "feedbackSuccessSubtle", _1c as "feedbackSuccessText", _1d as "feedbackWarning", _1e as "feedbackWarningBg", _1f as "feedbackWarningSubtle", _20 as "feedbackWarningText", _21 as "feedbackYes", _22 as "fog", _23 as "formBorder", _24 as "formBorderDisabled", _25 as "formInputBg", _26 as "ghost", _27 as "largeAndUp", _28 as "largeAndUpQuery", _29 as "largishAndUp", _2a as "largishAndUpQuery", _2b as "lavender", _2c as "linen", _2d as "link", _2e as "linkColor", _2f as "linkHoverColor", _30 as "mediumAndUp", _31 as "mediumAndUpQuery", _32 as "menuHover", _33 as "mexicanOpal", _34 as "mexicanOpalWebText", _35 as "mintChip", _36 as "mintChipLight", _37 as "pebble", _38 as "placeholder", _39 as "popoverColor", _3a as "popoverTextColor", _3b as "rose", _3c as "roseWebText", _3d as "rsvpMaybe", _3e as "rsvpNo", _3f as "rsvpYes", _40 as "shadow", _41 as "silver", _42 as "smallAndUp", _43 as "smallAndUpQuery", _44 as "spacingLg", _45 as "spacingMd", _46 as "spacingSm", _47 as "tanzanite", _48 as "tanzaniteWebText", _49 as "tigersEye", _4a as "tooltipColor", _4b as "tooltipTextColor", _4c as "vanilla", _4d as "waffle", _4e as "white" }
