import {evite} from 'evite';
import urls from 'urls';
import {computed, observable} from 'mobx';
import XDate from 'xdate';
import {SearchSuggestionCollection} from '../collections/SearchSuggestionCollection';
import {SearchSuggestionModel} from './SearchSuggestionModel';

let intervalId = 0;
const FIVE_MINUTES = 1000 * 60 * 5;
const memoise = new Map();
const MAX_SUGGESTION_RESULTS = 6;
const TRENDING_SEARCH_DAYS = 1;
const TRENDING_SEARCH_URL_NAME = 'ajax_gallery_searches';
const SUGGESTIONS_URL_NAME = 'ajax_gallery_suggestions';

// TODO - refactor
export class SearchModel {
  @observable ready = false;

  categories = [];

  suggestions = [];

  hints = [];

  templates = [];

  cache = {
    invitation: new Map(),
    card: new Map(),
  };

  constructor(attributes = {}) {
    Object.assign(this, this.parse(attributes));

    this.categoryCollection = new SearchSuggestionCollection(this.categories);
    this.suggestionCollection = new SearchSuggestionCollection(this.suggestions);
    this.hintCollection = new SearchSuggestionCollection(this.suggestions);
    this.templateCollection = new SearchSuggestionCollection(this.templates);
    this.urlName = SUGGESTIONS_URL_NAME;
    this.tracking = '';

    if (!intervalId) {
      intervalId = setInterval(() => {
        this.cache.invitation.clear();
        this.cache.card.clear();
      }, FIVE_MINUTES);
    }
  }

  static normalizeSearch(search) {
    if (memoise.has(search)) {
      return memoise.get(search);
    }

    const allWhitespaces = /\s+/g;
    const normalizedString = (search || '').trim().toLowerCase().replace(allWhitespaces, ' ');

    memoise.set(search, normalizedString);
    return normalizedString;
  }

  findWhere(options) {
    return (
      (options && this.categoryCollection.findWhere(options)) ||
      this.suggestionCollection.findWhere(options) ||
      this.hintCollection.findWhere(options) ||
      this.templateCollection.findWhere(options) ||
      null
    );
  }

  findIndexWhere(propsOrFunction) {
    if (!propsOrFunction) {
      return -1;
    }

    let index = this.categoryCollection.findIndexWhere(propsOrFunction);
    if (index !== -1) {
      return index;
    }

    index = this.suggestionCollection.findIndexWhere(propsOrFunction);
    if (index !== -1) {
      return index + this.categoryCollection.length;
    }

    index = this.hintCollection.findIndexWhere(propsOrFunction);
    if (index !== -1) {
      return index + this.categoryCollection.length + this.suggestionCollection.length;
    }

    index = this.templateCollection.findIndexWhere(propsOrFunction);
    if (index !== -1) {
      return (
        index +
        this.categoryCollection.length +
        this.suggestionCollection.length +
        this.hintCollection.length
      );
    }

    return -1;
  }

  executeSuggestionsRequest(url) {
    return evite
      .fetch(`${url}`, {
        credentials: 'include',
        headers: {
          'X-CSRFToken': evite.cookie('csrftoken'),
          Accept: 'application/json',
          'Content-Type': 'application/json',
        },
      })
      .then((response) => (response.ok ? response.json() : Promise.reject(response.statusText)));
  }

  fetchSuggestions(filter, search) {
    const url = `${urls.get(this.urlName, {search})}?filter=${filter}`;
    return this.executeSuggestionsRequest(url);
  }

  fetchTrendingSearches(filter) {
    const today = new XDate();
    const dateFormat = 'yyyy-MM-dd';
    const url = urls.get(this.urlName, {
      endDate: today.toString(dateFormat),
      startDate: today.addDays(-TRENDING_SEARCH_DAYS).toString(dateFormat),
      limit: MAX_SUGGESTION_RESULTS,
      filter,
    });

    return this.executeSuggestionsRequest(url).then((json) => ({suggestions: json}));
  }

  fetchSearchTerm(search, filter) {
    search = SearchModel.normalizeSearch(search);
    this.search = search;
    this.urlName = search ? SUGGESTIONS_URL_NAME : TRENDING_SEARCH_URL_NAME;
    const searchFilter = filter || (window.cards_page ? 'card' : 'invitation');

    if (this.cache[searchFilter].has(search)) {
      this.currentFetch = this.cache[searchFilter].get(search);
      return this.currentFetch;
    }

    this.currentFetch = (
      this.search ? this.fetchSuggestions(filter, search) : this.fetchTrendingSearches(filter)
    )
      .then((json) => ({search, ...(json || {})}))
      .catch((error) => {
        evite.error(`Error while retrieving search suggestions. errorThrown= ${error}`);
      });

    this.cache[searchFilter].set(search, this.currentFetch);
    return this.currentFetch;
  }

  async sync(searchOrJson) {
    this.ready = false;

    let json;
    if (typeof searchOrJson === 'string') {
      json = await this.fetchSearchTerm(searchOrJson);
    } else {
      json = searchOrJson;
    }

    Object.assign(this, this.parse(json));
    this.categoryCollection.reset(this.categories);
    this.suggestionCollection.reset(this.suggestions);
    this.hintCollection.reset(this.hints);
    this.templateCollection.reset(this.templates);
    this.ready = true;
  }

  parse(json = {}) {
    const parsedJson = {...json};

    parsedJson.search = json.search || '';
    parsedJson.status = json.status || 'unknown';
    parsedJson.tracking = json.tracking || '';

    const categories = (parsedJson.categories = (json.categories || json.category || []).concat());
    const suggestions = (parsedJson.suggestions = (json.suggestions || []).concat());
    const hints = (parsedJson.hints = (json.hints || []).concat());
    const templates = (parsedJson.templates = (json.templates || []).concat());

    // CATEGORY SUGGESTIONS
    let {length} = categories;
    for (let i = 0; i < length; ++i) {
      const categoryData = categories[i];
      categories[i] = new SearchSuggestionModel({
        id: categoryData.id,
        text: categoryData.text,
        type: SearchSuggestionModel.TYPE_CATEGORY,
      });
    }

    // KEYWORD SUGGESTIONS
    length = suggestions.length;
    for (let i = 0; i < length; ++i) {
      const suggestionData = suggestions[i];
      let text = '';
      let id = '';

      if (suggestionData instanceof Object) {
        id = suggestionData.id;
        text = suggestionData.text;
      } else {
        id = text = suggestionData;
      }

      suggestions[i] = new SearchSuggestionModel({
        id,
        text,
        type: SearchSuggestionModel.TYPE_SUGGESTION,
      });
    }

    // HINT SUGGESTIONS
    length = hints.length;
    for (let i = 0; i < length; ++i) {
      const hintData = hints[i];
      hints[i] = new SearchSuggestionModel({
        id: hintData.id,
        text: `${hintData.text} ("${hintData.id}"?)`,
        type: SearchSuggestionModel.TYPE_HINT,
      });
    }

    // TEMPLATE SUGGESTIONS
    length = templates.length;
    for (let i = 0; i < length; ++i) {
      const templateData = templates[i];
      templates[i] = new SearchSuggestionModel({
        id: templateData.id,
        text: templateData.text,
        type: SearchSuggestionModel.TYPE_TEMPLATE,
        src: templateData.image,
        data: templateData.template,
      });
    }

    return parsedJson;
  }

  @computed
  get numSuggestions() {
    return (
      this.categoryCollection.length +
      this.suggestionCollection.length +
      this.hintCollection.length +
      this.templateCollection.length
    );
  }

  @computed
  get noMatches() {
    return this.ready && this.numSuggestions === 0;
  }
}
